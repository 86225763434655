/* Camera.css */
.capture-button {
  position: absolute !important;
  bottom: 20px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: white !important;
  color: black !important;
  width: 70px !important;
  height: 70px !important;
}

.timer-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001;
}

.timer {
  width: 50px;
  height: 50px;
  border: 3px solid #FFF;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2);
}

.timer-fill {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #4CAF50;
  transition: height 1s linear;
}

.timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
}

.detection-info {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
  width: 300px;
}

.captured-image {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
  margin-bottom: 20px;
}

.primary-button {
  background-color: #2A2AC0 !important;
  color: #FFF !important;
  margin: 5px !important;
}

.secondary-button {
  background-color: #261D56 !important;
  color: #FFF !important;
  margin: 5px !important;
}

@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }
}

.detection-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 60%;
  border: 2px solid white;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.vehicle-silhouette {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
}

.detection-info {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  max-width: 200px;
}

.ai-message {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1000;
  text-align: center;
  max-width: 80%;
  animation: fadeInOut 5s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

@media (orientation: landscape) {
  .capture-button {
    right: 20px !important;
    left: auto !important;
    bottom: 50% !important;
    transform: translateY(50%) !important;
  }

  .timer-container {
    top: 20px;
    left: 20px;
    right: auto;
  }

  .detection-info {
    bottom: 20px;
    left: 20px;
    top: auto;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.back-button {
  position: absolute !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 1001 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: black !important;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

@media (orientation: landscape) {
  .back-button {
    bottom: 20px !important;
    right: 20px !important;
  }
}