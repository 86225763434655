/* index.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #2A2AC0 0%, #1A1A80 100%);
  color: #F9FCFF;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root, .App {
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
}

body {
  overflow-y: auto;
  position: relative;
}

@media screen and (max-width: 768px) {
  body {
    position: static;
    overflow-y: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  padding-left: 20px;
}

.container {
  padding: 20px;
  text-align: center;
}

.button {
  margin-top: 20px;
}

img {
  max-width: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timer {
  width: 50px;
  height: 50px;
  border: 5px solid #FFF;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.timer .fill {
  width: 100%;
  background: green;
  border-radius: 50%;
  transform-origin: center bottom;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 10px;
  }
}

.checklist-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
}

.checklist-box {
  border: 4px solid white;
  border-radius: 10px;
  transition: border-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checklist-box.checked {
  border-color: green;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Slide styles */
.slide-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #2A2AC0 0%, #1A1A80 100%);
  overflow: auto;
  position: relative;
}

.logo-box {
  transition: all 1s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-box-animate {
  top: 10%;
  transform: translate(-50%, 0);
}

.logo {
  width: 250px;
  height: auto;
}

.welcome-content {
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.welcome-content-animate {
  opacity: 1;
}

.button-container {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 350px;
  display: flex;
  justify-content: center;
}

.continue-button {
  background-color: #2a2ac0 !important;
  opacity: 0;
  transition: opacity 1s ease;
  width: 100%;
  border-radius: 12px;
  font-size: 16px;
  height: 48px;
  text-transform: none;
}

.continue-button-animate {
  opacity: 1;
}

.prosis-box {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease;
}

.prosis-box-animate {
  opacity: 0.7;
}

.prosis-logo {
  width: 100px;
  height: auto;
}

/* Styles for primary and secondary buttons */
.primary-button {
  background-color: #2A2AC0 !important;
  color: #FFF !important;
}

.secondary-button {
  background-color: #261D56 !important;
  color: #FFF !important;
}

.scroll-container {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}