.speedometer {
    width: 100px;
    height: 50px;
    background: linear-gradient(90deg, #ff0000, #ffff00, #00ff00);
    border-radius: 100px 100px 0 0;
    overflow: hidden;
    position: relative;
  }
  
  .speedometer-scale {
    width: 100%;
    height: 100%;
    background: conic-gradient(
      from 180deg,
      rgba(255, 255, 255, 0.8) 0deg,
      transparent 90deg,
      transparent 270deg,
      rgba(255, 255, 255, 0.8) 360deg
    );
  }
  
  .speedometer-needle {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 2px;
    height: 40px;
    background-color: #000;
    transform-origin: bottom center;
    transition: transform 0.3s ease;
  }
  
  .speedometer-value {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: bold;
    color: #000;
  }